import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './AuthContext';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import './MainConsole.css'; // Import the CSS for styling
import bossMessages from './BossMessages'; // Import the array of Boss messages
import { useLocation } from 'react-router-dom';

const MainConsole = ({ setModalOpen }) => {
    const [input, setInput] = useState('');
    const [responses, setResponses] = useState({ Boss: '', Hipster: '', Hacker: '', Hustler: '' });
    const [taskId, setTaskId] = useState(null);
    const [poolId, setPoolId] = useState(null); 
    const { user, refreshToken } = useAuth();
    const [isLoading, setIsLoading] = useState(false); // Add loading state for in progress tasks
    const inputRef = useRef(null);

    const [selectedCharacter, setSelectedCharacter] = useState(null);
    const handleCharacterSelect = (character) => {
        setSelectedCharacter(character === selectedCharacter ? null : character);
    };



    // Select Endpoint based on environment
    var HOST_URL = null;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code - run locally
        HOST_URL = 'http://127.0.0.1:8000';
    } else {
        // production code - run on the cloud
        HOST_URL = 'https://studio.techramp.io';
    }

    const API_URL = HOST_URL + '/tasks'

    const [idToken, setIdToken] = useState(localStorage.getItem('idToken'));  // Make idToken a state variable
    // const idToken = localStorage.getItem('idToken'); 

    // Update idToken in state when it changes in localStorage (e.g., on refresh or login)
    const updateIdToken = () => {
    setIdToken(localStorage.getItem('idToken'));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let currentIdToken = null;

        // Validation Step to check if user is logged in
        if (!user){
            console.error("No user found. User not logged in?");
        } 

        if (!idToken) {
            console.error("No idToken found. User not logged in?");
        }

        if (user && idToken){

            // Set Boss Message
            const randomMessage = bossMessages[Math.floor(Math.random() * bossMessages.length)];
            setResponses(prev => ({
                ...prev,
                Boss: prev.Boss + randomMessage + '\n\n' 
            }));

            setIsLoading(true); // Show the spinner when a task is in progress

            // Submit the task
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({ description: input }),
            });

            if (response.ok) {
                
                const { task_id, pool_id } = await response.json();
                setTaskId(task_id);
                setPoolId(pool_id);
                console.log('Task submitted successfully! Processing response for (taskiD) (pool_id)', task_id, pool_id);
            } else {
                setIsLoading(false);
                // Handle different errors codes
                if (response.status ===  403) {
                    // create modal to show use has not verified email
                    console.error('User not verified');
                    alert("User not verified - A verification email has been sent. Please check your inbox.");
                } else if (response.status === 401) {
                    console.error('Unauthorized - Token likely expired');
                    // Try to refresh the token and retry
                    alert("Unauthorized Error - User token likely expired. Try refreshing the page.");
                }   
                else {
                    alert("Error submitting task with code: " + response.statusText + " " + response.status);
                    console.error('Error submitting task, code:', response.statusText, response.status);
                }
            }
            setInput('');
        } else {
            console.log("User not logged in???");
            setModalOpen(true);
            setInput('');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        //Update the idToken in state when the user logs in
        const currentIdToken = localStorage.getItem('idToken');
        console.log("User update detected - Setting idToken in state to:", currentIdToken);
        setIdToken(currentIdToken);
    }, [user]);

    useEffect(() => {
        console.log("MainConsole: useEffect: taskId:", taskId, "user:", user, "idToken:", idToken);
        if (taskId && idToken) {
            fetchEventSource(HOST_URL+`/task_stream/${taskId}/${poolId}`, {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                },
                onmessage(event) {
                    const data = JSON.parse(event.data);
                    console.log("Parsed Data:", data);

                    setResponses(prev => ({
                        ...prev,
                        [data.data.character]: (prev[data.data.character] || '') + data.data.data + '\n'
                    }));

                    if (data.event === 'complete') {
                        console.log('Task completed!');
                        setTaskId(null);
                        setIsLoading(false);
                    } 
                },
                onerror(error) {
                    console.error("EventSource error:", error);
                    setTaskId(null); 
                    setIsLoading(false);
                },
            });
        } else {
            console.log("useEffect: taskId or user is not set"); 
        }

        return () => {
            console.log("Return Statement Closing connection!");
        };

    }, [taskId, idToken]); // Should User be here?

    useEffect(() => {
        // This code will run ONLY ONCE when the component mounts
        const storedValue = sessionStorage.getItem('requestContext');
        if (storedValue) {
          setInput(storedValue); 
          // sessionStorage.removeItem('requestContext');  // Optional: Clear after retrieval 
        }
      }, []); // Empty dependency array to run only on mount

    return (
        <div className="main-console">
            
            {/* CEO PANEL */}
            <div className="ceo-panel">
                <img src="/character_images/boss_1.png" alt="CEO" className="ceo-image" />
                <div className="response">
                    <pre className="agent-response">{responses['Boss']}</pre>
                </div>

                {/* User Input Form */}
                <form onSubmit={handleSubmit} className="console-form">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Enter your input for the Boss"
                        className="console-input"
                    />
                    <button type="submit" className="submit-button">Send {isLoading && <div className="loading-spinner"></div>} </button>
                </form>
            </div>

            {/* Worker PANELS */}
            <div className="worker-section">
                {['Hipster', 'Hacker', 'Hustler'].map((agent) => (
                    <div
                        key={agent}
                        className={`worker-container ${agent === selectedCharacter ? 'selected' : ''}`}
                        onClick={() => handleCharacterSelect(agent)}
                    >
                        <div 
                            key={agent} 
                            className="worker-pane">
                            {/* TODO: Put newer images here (fix map thingy) */}
                            <img src={`/character_images/${agent}_1.png`} alt={agent} className="worker-image" />
                            <div className="response">
                                <pre className="agent-response">{responses[agent]}</pre>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
</div>
    );
};

export default MainConsole;
