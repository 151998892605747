/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, June 2024
 */

// Bar.js
import './Bar.css'; // Assuming styles for the bar are here
import React, { useState, useEffect } from 'react';
import LoginModal from './LoginModal';
import { useAuth } from './AuthContext';
import { Link, useNavigate } from 'react-router-dom';

const Bar = ({ children, isModalOpen, setModalOpen }) => {
  const { user, logout } = useAuth();
  const [sidebarState, setSidebarState] = useState(window.innerWidth > 768 ? 'open' : 'closed');
  const navigate = useNavigate();

  const toggleSidebarState = () => {
    setSidebarState(sidebarState === 'open' ? 'closed' : 'open');
  };

  // Add useEffect to handle window resizing
  useEffect(() => {
    const handleResize = () => {
      setSidebarState(window.innerWidth > 768 ? 'open' : 'closed');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  console.log("Bar: Rendering - User", user);
  return (
    <div className={`bar-container ${sidebarState === 'open' ? 'open' : 'closed'}`}>

      {/* Main section where each individual page loads */}
      <div className="main-content">
        <button className={`hamburger-button ${sidebarState === 'open' ? 'open' : 'closed'}`} onClick={toggleSidebarState}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>

        {/* Load the rest of the page */}
        {children} 
      </div>

      {/* Sidebar */}
      <div className={`sidebar-container ${sidebarState === 'open' ? 'open' : 'closed'}`}> 
        <div className={`sidebar ${sidebarState === 'open' ? 'open' : 'closed'}`}>
          <div className="logo-wrapper">
            <Link to="/">
                {sidebarState === 'closed' ? (
                    <img src="minimized_logo.png" alt="TechRamp Logo" className="logo-icon" /> 
                ) : (
                    <img src="/character_images/logo-01.png" alt="TechRamp Logo" className="logo" />
                )}
            </Link>
          </div>

          <hr className="sidebar-divider" /> {/* <-- Divider line */}


          <div className="minimize-container"> {/* <-- New container */}
            <button onClick={toggleSidebarState} className="minimize-button">
              {sidebarState === 'closed' ? (
                  <img src="right_arrow_icon.svg" alt="Minimize Sidebar" />
              ) : (
                <img src="left_arrow_icon.svg" alt="Minimize Sidebar" />
              )}
            </button>
          </div> 

          {/* Login/Account section */}
          <div className="memory-section">
          </div>

          {/* Navigation Links */}
          <div className="nav-links-wrapper">
            <div className="nav-links">
              <Link to="/" className="nav-link">About</Link> 
              | {/* Separator */}

              <div> {/* Wrap Subscription button */}
                <Link 
                  to="/subscriptions" 
                  className="nav-link"
                  onClick={(e) => {
                    if (!user) { // Only prevent default and open modal if not logged in
                      e.preventDefault();
                      setModalOpen(true);
                    } 
                  }}
                >
                  Subscription
                </Link>
              </div>
              |

              <div> {/* LOGIN LINK */}
                {user ? ( // Conditionally render Logout button or Login link
                <>
                  <a href="/" className="nav-link" onClick={logout}> 
                    Logout
                  </a>
                  </>
                ) : (
                  <>
                  <a href="#" className="nav-link" onClick={() => setModalOpen(true)}>Login</a>
                  {isModalOpen && <LoginModal close={() => setModalOpen(false)} />}
                  </>
                )}
              </div>



            </div>
            <hr className="nav-separator" /> {/* Horizontal line */}
          </div>

          {/* Copyright */}
          <div className="copyright">© 2024 TechRamp, LLC - All Rights Reserved</div>
        </div>
      </div>
    </div>
  );
};

export default Bar;